import type { Authorization } from '#common/model/landing';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Theme } from '../../../utils/login/types';

import { isCustomAuth } from '../../../experiments/customAuth/utils';
import { isEAMerchant } from '../../../experiments/ea-constants';
import { Environment } from '../../../utils/types';
import { UserAccountEAAuthService } from './partner-services/user-account-ea-auth-service';
import { UserAccountWBAuthService } from './partner-services/user-account-wb-auth-service';
import {
  IUserAccountIdServiceProps,
  UserAccountIdService,
} from './user-account-id-service';
import {
  IUserAccountLoginServiceProps,
  UserAccountLoginService,
} from './user-account-login-service';

import { AuthorizationType } from '#common/model/landing';

type IUserAccountServiceJwt = {
  isPreviewFrameMode: boolean;
  projectId: string;
  locale: FiveSymbolLocale;
  loginId: string;
  loginWidgetGeneration: 0 | 1 | null;
  loginTheme: Theme;
  merchantId: string;
};

export const getLoginService = ({
  merchantId,
  projectId,
  locale,
  loginId,
  loginWidgetGeneration,
  loginTheme,
  auth,
}: IUserAccountServiceJwt & {
  auth: Authorization;
  environment: Environment;
}) => {
  const loginData: IUserAccountLoginServiceProps = {
    merchantId,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  };
  const userIdData: IUserAccountIdServiceProps = {
    projectId,
    merchantId,
    auth,
  };
  if (isCustomAuth()) {
    return UserAccountWBAuthService.getInstance(loginData);
  }
  if (auth.type === AuthorizationType.LOGIN && loginId) {
    return UserAccountLoginService.getInstance(loginData);
  }
  if (isEAMerchant(merchantId)) {
    return UserAccountEAAuthService.getInstance(userIdData);
  }
  if (auth.type === AuthorizationType.USER_ID) {
    return UserAccountIdService.getInstance(userIdData);
  }
  return {};
};
